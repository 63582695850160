import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { DatePipe } from '@angular/common';
import { CardIO } from '@ionic-native/card-io/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';

import { WebcheckoutPageModule } from './pages/webcheckout/webcheckout.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    WebcheckoutPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    InAppBrowser,
    Network,
    OneSignal,
    Facebook,
    GooglePlus,
    SocialSharing,
    CardIO,
    DatePipe,
    SignInWithApple,
    PhotoViewer,
    Stripe,
    OpenNativeSettings,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { TickeraService } from 'src/app/services/tickera.service';
import { Component } from '@angular/core';
import { Platform, Events, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Calendar',
      url: '/calendar',
      icon: 'calendar'
    }
  ];

  
  currentUserDisplayName: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private events: Events,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private storage: Storage,
    private TCS: TickeraService
  ) {
    this.initializeApp();
    // catch events and show side menu items after login
    this.events.subscribe('loggedIn', (displayName) => {
      this.currentUserDisplayName = displayName;
      //console.log('Events worked: ',this.currentUserDisplayName);
    });
    this.currentUserDisplayName = localStorage.getItem('display_name');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is('cordova')) {
        this.setupPush();
      }
    });
  }
  
  setupPush() {
    // "YOUR_APPID", "YOUR_GOOGLE_PROJECT_NUMBER_IF_ANDROID"
    this.oneSignal.startInit('1fa0534f-620d-467d-adc4-1f5bb82d4c76', '555520010156');
 
    // prompt for permission
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      this.showAlert(title, msg, additionalData.task,additionalData.eventId,additionalData.albumId,additionalData.specilaPostId);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let msg = data.notification.payload.body;
      let title = data.notification.payload.title;
      let additionalData = data.notification.payload.additionalData;
 
      this.showAlert(title, msg, additionalData.task,additionalData.eventId,additionalData.albumId,additionalData.specilaPostId);
    });
 
    this.oneSignal.endInit();

    if(this.platform.is('ios')) {
      setTimeout(() => {
        this.oneSignal.getPermissionSubscriptionState().then((permSubStResp) => {
          localStorage.setItem('pushAllowed', permSubStResp.permissionStatus.status);
        });
      }, 2000);
    }
  }
 
  async showAlert(title, msg, task, eventId, albumId, specilaPostId) {

    if(eventId){
      let trimmedEventId = eventId.replace(/\s/g, '');
      //console.log('trimmedEventId:',trimmedEventId);
      const alert = await this.alertCtrl.create({
        header: title,
        subHeader: msg,
        buttons: [
          {
            text: `Action: ${task}`,
            handler: () => {
              this.router.navigateByUrl(`/pushedevent/${trimmedEventId}`);
            }
          }
        ]
      });
      alert.present();
    }

    if(albumId){
      const alert = await this.alertCtrl.create({
        header: title,
        subHeader: msg,
        buttons: [
          {
            text: `Action: ${task}`,
            handler: () => {
              this.router.navigateByUrl(`/albums/${albumId}`);
            }
          }
        ]
      });
      alert.present();
    }

    if(specilaPostId){
      const alert = await this.alertCtrl.create({
        header: title,
        subHeader: msg,
        buttons: [
          {
            text: `Action: ${task}`,
            handler: () => {
              this.router.navigateByUrl(`/announcement/${albumId}`);
            }
          }
        ]
      });
      alert.present();
    }


   
  }

  logOut(){
    localStorage.removeItem('currentUserId');
    localStorage.removeItem('access_token');
    localStorage.removeItem('display_name');
    // clear all storage except card save
    this.storage.forEach((value,key) => {
      if(key.includes('cartProduct_')){
        let parseFromStorage = JSON.parse(value);
        this.storage.remove(`cartProduct_${parseFromStorage.id}`);
      }
    }).then(() => {
    });

    this.router.navigateByUrl('/login');
    window.location.reload();
  }
  
}

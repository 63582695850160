import { Injectable } from '@angular/core';
import { ToastController, AlertController } from "@ionic/angular";
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class CommonfunctionService {

  constructor(
    private storage: Storage,
    private toastCtrl: ToastController, 
    private alertCtrl: AlertController
    ) { }

  async presentToast(messageToShow,button,mesgPosition,showDuration){
    const toast = await this.toastCtrl.create({
      message: messageToShow,
      showCloseButton: button,
      position: mesgPosition,
      duration: showDuration
    });
    toast.present();
}

presentAlert(alrtHeader,alrtMessage) {
  let alert = this.alertCtrl.create({
    header: alrtHeader,
    subHeader: alrtMessage,
    buttons: ['Dismiss']
  }).then ((alert) =>{
   alert.present();
  });
 
}

validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// for calendar page
newCalendarFilterEvent() {
  // Declare variables
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("newCalEventTitleInput");
  filter = input.value.toUpperCase();
  //console.log('Typed Value: ',filter);
  table = document.getElementById("newCalEventsContainer");
  tr = table.getElementsByTagName("ion-col");
  //console.log('tr length: ', tr.length)

  // Loop through all table rows, and hide those who don't match the search query
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("h3")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}

filterEvent() {
  // Declare variables
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("eventTitleInput");
  filter = input.value.toUpperCase();
  //console.log('Typed Value: ',filter);
  table = document.getElementById("eventsContainer");
  tr = table.getElementsByTagName("ion-col");
  //console.log('tr length: ', tr.length)

  // Loop through all table rows, and hide those who don't match the search query
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("ion-card-subtitle")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}

expireCart(){
  // Reset when storage is more than 30 minutes
  let minutes = 30; 
  let now = new Date().getTime();
  let setupTime = localStorage.getItem('setupTime');
  if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString());
  } else {
      if((now - parseInt(setupTime)) > (minutes*60*1000)) {
          localStorage.removeItem('setupTime');
          localStorage.setItem('setupTime', now.toString());
          // remove items from cart
          this.storage.forEach((value,key) => {
            if(key.includes('cartProduct_')){
              let parseFromStorage = JSON.parse(value);
              this.storage.remove(`cartProduct_${parseFromStorage.id}`);
            }
          });
      }
  }
  //console.log('expireCart: ', setupTime);
}

}

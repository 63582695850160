import { Component, OnInit, Input } from '@angular/core';
import { CommonfunctionService } from 'src/app/services/commonfunction.service';
import { Router } from '@angular/router';
import { WoocommerceService } from 'src/app/services/woocommerce.service';
import { Storage } from '@ionic/storage'
import { CartService } from 'src/app/services/cart.service';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-webcheckout',
  templateUrl: './webcheckout.page.html',
  styleUrls: ['./webcheckout.page.scss'],
})
export class WebcheckoutPage implements OnInit {

  @Input() siteURL: string;
  webURL: any;

  disableBtn: boolean = false;
  showLoader: boolean = true;

  constructor(
    private WC: WoocommerceService,
    private CFS: CommonfunctionService,
    private cartService: CartService,
    private router: Router,
    private modalController: ModalController,
    private storage: Storage,
    private DS: DomSanitizer,
    ) {
     }

  ngOnInit() {
    this.showLoader = true;
    //console.log('showLoader iframe starts: ',this.showLoader);
    console.log('Modal page URL Received: ',this.siteURL);
    this.webURL = this.DS.bypassSecurityTrustResourceUrl(this.siteURL);

  }

  onLoad() {
    this.showLoader = false;
    //console.log('showLoader iframe loaded: ',this.showLoader);
  }


  modalClose() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}

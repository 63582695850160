import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WoocommerceService {

  product: any;
  paymentGateways: any;
  orderResp:any;
  apiUrl: string;
  customerData: any;
  couponDetails: any;
  insuranceDetails: any;
  couponId: any;
  countries: any;
  states: any;

  siteURL = 'https://eventpro.ky';
  //siteURL = 'https://eventprokystg.wpengine.com';
  jwtPart = '/wp-json/jwt-auth/v1/token';
  userPart = '/wp-json/wp/v2/users/';
  woocomPart: string = '/wp-json/wc/v3/';
  tickeraAPI = '/wp-json/tickera-api/v1/';
  secretkey = 'EPHGtmJDfqbPJH';
  consumerKey: string = "ck_07da5a29160d2af910a8df98fa36e952aecf3643";
  consumerSecret: string = "cs_f64d91d0d8933445aea2fb21c30f6c9cca5e65c3";
  pnpVisaMasterCardURL: string = '/wp-json/wc/v3/plugnpay-payment/';
  pnpAmexURL = '/wp-json/wc/v3/plugnpayamex-payment/';
  //plugNPayDirectClientKey = old 'uEebTxyzPI89e5Cf0ajfmGCmQ';
  plugNPayRemoteClientPassword = 'TV4WsJgSaHPUkPQ7';
  plugNPayDirectClientKey = 'd91d0d8933445aea2fb21c30f6c9cca5e65';
  stripeVisaMasterURL: string = '/wp-json/wc/v3/stripe-payment/';
  api_token: string = 'ck_b1f1c466adc9e2c1aaef2358b82ff84deb130c4b';
  
  insuranceURL = 'https://eventpro.ky/wp-json/wc/v3/protect-ticket-insurance?secretkey=EPHGtmJDfqbPJH';

  constructor(private http: HttpClient) { }



  validateCardNumber(currentCard) {
    // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
    // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also
    // American Express
    let amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
    // Visa
    let visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    let mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
    //Discover
    let discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    //6011, 622126-622925, 644-649, 65

  if (amex_regex.test(currentCard)) {
      return currentCard;
    } else if (visa_regex.test(currentCard)) {
      return currentCard;
    } else if (mastercard_regex.test(currentCard)) {
      return currentCard;
    } else if (discover_regex.test(currentCard)) {
      return currentCard;
    } else {
      return false;
    }

}

  updateCustomer(customerId, userObj){
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let formattedUserObj = this.JSON_to_URLEncoded(userObj);
    this.apiUrl = `${this.siteURL}${this.woocomPart}customers/${customerId}?consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}`;
    //console.log('API URL for register a customer: ', this.apiUrl);
    return new Promise ((resolve) => {
      this.http.put(this.apiUrl, formattedUserObj, {headers}).subscribe((successResp) => {
          resolve(successResp);
      },
      (errorResp) => {
        resolve(errorResp);
        //console.log('errorResp:',errorResp);
      }
      )
    });
  }

  getCustomerData(id){
    this.apiUrl = `${this.siteURL}${this.woocomPart}customers/${id}?consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}`;
    //console.log('API url for retrive customer: ', this.apiUrl);
    this.customerData = this.http.get(this.apiUrl); 
    return this.customerData;
  }

  getSingleProduct(productId){
    this.apiUrl = `${this.siteURL}${this.woocomPart}products/${productId}?consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}&status=publish`;
    //console.log('Single Product ULR: ',this.apiUrl);
    return new Promise((resolve) => {
      this.product = this.http.get(this.apiUrl);
      this.product.subscribe((data) => {
          resolve(data);
      });
    });
  }

  getCouponId(couponCode){
    this.apiUrl = `${this.siteURL}/wp-json/wc/v3/coponcode/${couponCode}`;
    //console.log('API url for coupon id: ', this.apiUrl);
    this.couponId = this.http.get(this.apiUrl); 
    return this.couponId;
  }

  getCouponDetails(couponId){
    this.apiUrl = `${this.siteURL}${this.woocomPart}coupons/${couponId}?consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}`;
    //console.log('API url for coupon details: ',this.apiUrl);
    return new Promise((resolve) => {
      this.product = this.http.get(this.apiUrl);
      this.product.subscribe((data) => {
          resolve(data);
      });
    });
  }

  getInsuranceDetails(){
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    this.apiUrl = this.insuranceURL;
    //console.log('URL for getInsuranceDetails');
    this.insuranceDetails = this.http.post(this.apiUrl, '', {headers});
    return this.insuranceDetails;
  }

  getAllPaymentGateWays(){
    this.apiUrl = `${this.siteURL}${this.woocomPart}payment_gateways?consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}&enabled=true`;
    //console.log('API URL for all paymnet gateways',this.apiUrl);
    return new Promise((resolve) => {
      this.paymentGateways = this.http.get(this.apiUrl);
      this.paymentGateways.subscribe((data) => {
        resolve(data);
      });
    });
  }

   // convert javascript object to x-www-form-urlencoded format
   JSON_to_URLEncoded(element, key?, list?) {
    var list = list || [];
    if (typeof element == "object") {
      for (var idx in element)
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          list
        );
    } else {
      list.push(key + "=" + encodeURIComponent(element));
    }
    return list.join("&");
  }
  
  placeOrder(orderDataObj){
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  
    let orderData = this.JSON_to_URLEncoded(orderDataObj);
  
    this.apiUrl = `${this.siteURL}${this.woocomPart}orders?consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}`;
    //console.log('API URL for order: ', this.apiUrl);
  
    return new Promise ((resolve) => {
      this.orderResp = this.http.post(this.apiUrl,orderData, {headers});
      this.orderResp.subscribe((successResp) => {
        resolve(successResp);
      },
  
      (errorResp) =>{
        resolve(errorResp);
      }
      
      );
    });
  
  }

  stripeVisaMaster(paymentInfo){
  
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  
    let payment_method = paymentInfo.payment_method;
    let order_id = paymentInfo.order_id;
    let token_id = paymentInfo.token_id;
  
    let paymentInfoFormatted = `payment_method=${payment_method}&order_id=${order_id}&token_id=${token_id}&api_token=${this.api_token}`;
    this.apiUrl = `${this.siteURL}${this.stripeVisaMasterURL}?${paymentInfoFormatted}`;
    //console.log('stripeVisaMaster: ',this.apiUrl);
  
    return new Promise ((resolve) => {
      this.http.post(this.apiUrl, paymentInfoFormatted, {headers}).subscribe((successResp) => {
          resolve(successResp);
      },
      (errorResp) => {
        resolve(errorResp);
      }
      )
    });
  }

  
 plugnpayVisaMaster(paymentInfo){
  
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  
    let payment_method = paymentInfo.payment_method;
    let order_id = paymentInfo.order_id;
    let plugnpaydirect_card_name = paymentInfo.plugnpaydirect_card_name;
    let plugnpaydirect_card_number = paymentInfo.plugnpaydirect_card_number;
    let plugnpaydirect_card_expiry_month = paymentInfo.plugnpaydirect_card_expiry_month;
    let plugnpaydirect_card_expiry_year = paymentInfo.plugnpaydirect_card_expiry_year;
    let plugnpaydirect_card_cvc = paymentInfo.plugnpaydirect_card_cvc;
  
    let paymentInfoFormatted = `payment_method=${payment_method}&order_id=${order_id}&plugnpaydirect-client-key=${this.plugNPayDirectClientKey}&plugnpaydirect-card-name=${plugnpaydirect_card_name}&plugnpaydirect-card-number=${plugnpaydirect_card_number}&plugnpaydirect-card-expiry-month=${plugnpaydirect_card_expiry_month}&plugnpaydirect-card-expiry-year=${plugnpaydirect_card_expiry_year}&plugnpaydirect-card-cvc=${plugnpaydirect_card_cvc}`;
    this.apiUrl = `${this.siteURL}${this.pnpVisaMasterCardURL}?${paymentInfoFormatted}`;
  
    //console.log('plugnpayVisaMaster API URL: ',this.apiUrl);

    return new Promise ((resolve) => {
      this.http.post(this.apiUrl, paymentInfoFormatted, {headers}).subscribe((successResp) => {
          resolve(successResp);
      },
      (errorResp) => {
        resolve(errorResp);
      }
      )
    });
  }

  plugnpayAmex(paymentInfo){
  
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  
    let payment_method = paymentInfo.payment_method;
    let order_id = paymentInfo.order_id;
    let plugnpaydirect_card_name = paymentInfo.plugnpaydirect_card_name;
    let plugnpaydirect_card_number = paymentInfo.plugnpaydirect_card_number;
    let plugnpaydirect_card_expiry_month = paymentInfo.plugnpaydirect_card_expiry_month;
    let plugnpaydirect_card_expiry_year = paymentInfo.plugnpaydirect_card_expiry_year;
    let plugnpaydirect_card_cvc = paymentInfo.plugnpaydirect_card_cvc;
  
    let paymentInfoFormatted = `payment_method=${payment_method}&order_id=${order_id}&plugnpaydirectamex-client-key=${this.plugNPayDirectClientKey}&plugnpaydirectamex-card-name=${plugnpaydirect_card_name}&plugnpaydirectamex-card-number=${plugnpaydirect_card_number}&plugnpaydirectamex-card-expiry-month=${plugnpaydirect_card_expiry_month}&plugnpaydirectamex-card-expiry-year=${plugnpaydirect_card_expiry_year}&plugnpaydirectamex-card-cvc=${plugnpaydirect_card_cvc}`;
    this.apiUrl = `${this.siteURL}${this.pnpAmexURL}?${paymentInfoFormatted}`;
    //console.log('plugnpayAmex API URL: ',this,this.apiUrl);
    return new Promise ((resolve) => {
      this.http.post(this.apiUrl, paymentInfoFormatted, {headers}).subscribe((successResp) => {
          resolve(successResp);
      },
      (errorResp) => {
        resolve(errorResp);
      }
      )
    });
  }

  paymentViaFreeTicket(orderId){
    
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  
    this.apiUrl = `${this.siteURL}${this.woocomPart}process-eventpro-wc-freeticket/?payment_method=wc_ep_freeticket_offline&order_id=${orderId}`;
    //console.log('paymentViaFreeTicket API URL: ',this.apiUrl);
  
    return new Promise((resolve) => {
      this.http.post(this.apiUrl, '', {headers}).subscribe((successResp) =>{
        resolve(successResp);
      },
      (errorResp) => {
        resolve(errorResp);
      })
    });
  }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';

declare var CryptoJSAesJson;

@Injectable({
  providedIn: 'root'
})
export class TickeraService {


  //siteURL: string = 'https://eventpro.ky';
  siteURL: string = 'https://eventprokydev.wpengine.com';

  apiURL: any;

  sliderEvents: any;
  titleEvents: any;
  upcomingEvents: any;
  allEvents: any;
  boatCruiseEvents: any;
  carnivalEvents: any;
  charityEvents: any;
  clubEvents: any;
  concertEvents: any;
  conferenceEvents: any;
  coolerfeteEvents: any;
  feteEvents: any;
  foodfestEvents: any;
  partyEvents: any;
  sportsEvents: any;
  othersEvents: any;
  singleEvent: any;

  todayEvents: any;
  thisWeekendEvents: any;
  thisWeekEvents: any;
  nextWeekEvents: any;
  thisMonthEvents: any;
  nextMonthEvents: any;
  justAnnounceEvents: any;
  mostPopularEvents: any;

  filteredEvents: any;

  tickets: any;
  attendee: any;
  currentAppVersion: any;
  blockedUsers: any;
  eventViewCount: any;

  /* For Live Site  */

  eventsTodayURL = '/wp-json/wp/v2/tc_events?filterBy=today';
  eventsThisWeekendURL = '/wp-json/wp/v2/tc_events?filterBy=weekend';
  eventsThisWeekURL = '/wp-json/wp/v2/tc_events?filterBy=thisweek';
  eventsNextWeekURL = '/wp-json/wp/v2/tc_events?filterBy=nextweek';
  eventsThisMonthURL = '/wp-json/wp/v2/tc_events?filterBy=thismonth';
  eventsNextMonthURL = '/wp-json/wp/v2/tc_events?filterBy=nextmonth';
  eventsJustAnnouncedURL = '/wp-json/wp/v2/tc_events?filterBy=just-anounce';
  eventsMostPopularURL ='/wp-json/wp/v2/tc_events?filterBy=popular';

  eventsFilteredURL = '/wp-json/wp/v2/tc_events';

  appVersionURL = '/wp-json/v2/app-version/epmain/198270';
  blockUserURL = '/wp-json/v2/app-userblock/current-user/198270';
  
  allEventsURL: string = '/wp-json/wp/v2/tc_events?catid=2218&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  sliderEventsURL: string = '/wp-json/wp/v2/tc_events?catid=2219&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  boatCruiseEventsURL:  string =  '/wp-json/wp/v2/tc_events?catid=2220&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  carnivalEventsURL:  string =  '/wp-json/wp/v2/tc_events?catid=2221&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  charityEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2222&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  clubEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2223&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  concertEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2224&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  conferenceEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2225&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  coolerfeteEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2226&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  feteEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2231&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  foodfestEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2230&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  partyEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2227&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  sportsEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2229&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  othersEventsURL: string =  '/wp-json/wp/v2/tc_events?catid=2228&filter[meta_key]=event_date_time&order=asc&per_page=100&status=publish';
  singleEventURL: string = '/wp-json/wp/v2/tc_events/';
  ticketsURL: string = '/wp-json/appevent/v2/my-tickets/';

  tickeraAPI = '/wp-json/tickera-api/v1/';
  eventManagerAPI = '/wp-json/eventmanager-api/v2/';

  secretkey = 'R5KbUMNv3hOnY5LShwgqr4gk';
  salt = localStorage.getItem('currentUserEmail');
  userId = localStorage.getItem('currentUserId');
  encryptedKey: any;


  // for capella

  capellaSecretKey = 'EyiVYzHiDg3lM8o3WOD21wcW2F';
  capellaSiteURL = 'https://capella.eventpro.ky';
  
  constructor(private http: HttpClient) { }





  getEventsByTitle(title) {
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let dataObj = {
      event_title: title,
    };

    let finalDataObj = this.JSON_to_URLEncoded(dataObj);
    this.apiURL = `${this.siteURL}${this.eventManagerAPI}appevent-get-events-by-title`;
    //console.log('getEventsByTitle API URL: ', this.apiURL);
    return new Promise((resolve) => {
      this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
        resolve(successResp);
      })
    });
  }


  encryptMe(data){
    this.encryptedKey = CryptoJSAesJson.encrypt(JSON.stringify(data), this.salt).toString();
    //console.log('Encrypted Key: ', this.encryptedKey);
    this.encryptedKey =  btoa(this.encryptedKey);
    //console.log('After base64 encode: ', this.encryptedKey);
    return this.encryptedKey;
  }


  //Attendee List App Only Endpoint Serial #2
  getCurrentAppVersion() {
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let dataObj = {
      app_name: 'com.eventproapp.app',
    };

    let finalDataObj = this.JSON_to_URLEncoded(dataObj);
    this.apiURL = `${this.siteURL}${this.tickeraAPI}version-control`;
    //console.log('getCurrentAppVersion API URL: ', this.dataUrl);
    return new Promise((resolve) => {
      this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
        resolve(successResp);
      })
    });
  }

  getBlockedUsers(){
    this.apiURL = `${this.siteURL}${this.blockUserURL}`;
    return new Promise((resolve) => {
      this.http.get(this.apiURL).subscribe((successResp) =>{
        resolve(successResp);
      });
    });
  }

  getFeaturedEvents(){
    this.apiURL = `${this.siteURL}${this.sliderEventsURL}`;
    this.sliderEvents = this.http.get(this.apiURL);
    //console.log('From getFeaturedEvents');
    return this.sliderEvents;
    
  }

  getAllEvents(){
    this.apiURL = `${this.siteURL}${this.allEventsURL}`;
    this.allEvents = this.http.get(this.apiURL);
    //console.log('From getAllEvents');
    return this.allEvents;
  }

  getBoatCruiseEvents(){
    this.apiURL = `${this.siteURL}${this.boatCruiseEventsURL}`;
    this.boatCruiseEvents = this.http.get(this.apiURL);
    //console.log('From getBoatCruiseEvents');
    return this.boatCruiseEvents;
  }

  getCarnivalEvents(){
    this.apiURL = `${this.siteURL}${this.carnivalEventsURL}`;
    this.carnivalEvents = this.http.get(this.apiURL);
    //console.log('From getCarnivalEvents');
    return this.carnivalEvents;
  }

  getCharityEvents(){
    this.apiURL = `${this.siteURL}${this.charityEventsURL}`;
    this.charityEvents = this.http.get(this.apiURL);
    //console.log('From getCharityEvents');
    return this.charityEvents;
  }

  getClubEvents(){
    this.apiURL = `${this.siteURL}${this.clubEventsURL}`;
    this.clubEvents = this.http.get(this.apiURL);
    //console.log('From getClubEvents');
    return this.clubEvents;
  }

  getConcertEvents(){
    this.apiURL = `${this.siteURL}${this.concertEventsURL}`;
    this.concertEvents = this.http.get(this.apiURL);
    //console.log('From getConcertEvents');
    return this.concertEvents;
  }

  getConferenceEvents(){
    this.apiURL = `${this.siteURL}${this.conferenceEventsURL}`;
    this.conferenceEvents = this.http.get(this.apiURL);
    //console.log('From getConferenceEvents');
    return this.conferenceEvents;
  }

  getCoolerFeteEvents(){
    this.apiURL = `${this.siteURL}${this.coolerfeteEventsURL}`;
    this.coolerfeteEvents = this.http.get(this.apiURL);
    //console.log('From getCoolerFeteEvents');
    return this.coolerfeteEvents;
  }

  getFeteEvents(){
    this.apiURL = `${this.siteURL}${this.feteEventsURL}`;
    this.feteEvents = this.http.get(this.apiURL);
    //console.log('From getFeteEvents');
    return this.feteEvents;
  }

  getFoodfestEvents(){
    this.apiURL = `${this.siteURL}${this.foodfestEventsURL}`;
    this.foodfestEvents = this.http.get(this.apiURL);
    //console.log('From getFoodfestEvents');
    return this.foodfestEvents;
  }

  getPartyEvents(){
    this.apiURL = `${this.siteURL}${this.partyEventsURL}`;
    this.partyEvents = this.http.get(this.apiURL);
    //console.log('From getPartyEvents');
    return this.partyEvents;
  }

  getSportsEvents(){
    this.apiURL = `${this.siteURL}${this.sportsEventsURL}`;
    this.sportsEvents = this.http.get(this.apiURL);
    //console.log('From getSportsEvents');
    return this.sportsEvents;
  }

  getOthersEvents(){
    this.apiURL = `${this.siteURL}${this.othersEventsURL}`;
    this.othersEvents = this.http.get(this.apiURL);
    //console.log('From getOthersEvents');
    return this.othersEvents;
  }

  getSingleEvent(eventId){
    let apiURL = `${this.siteURL}${this.singleEventURL}${eventId}`;
    //console.log('From getSingleEvent: ',this.singleEventURL);
    this.singleEvent = this.http.get(apiURL);
    return this.singleEvent;
  }


  getEventsToday(){
    this.apiURL = `${this.siteURL}${this.eventsTodayURL}`;
    this.todayEvents = this.http.get(this.apiURL);
    //console.log('From getEventsToday: ', this.eventsTodayURL);
    return this.todayEvents;
  }

  getEventsThisWeekend(){
    this.apiURL = `${this.siteURL}${this.eventsThisWeekendURL}`;
    this.thisWeekendEvents = this.http.get(this.apiURL);
    //console.log('From getEventsThisWeekend: ',this.eventsThisWeekendURL);
    return this.thisWeekendEvents;
  }

  getEventsThisWeek(){
    this.apiURL = `${this.siteURL}${this.eventsThisWeekURL}`;
    this.thisWeekEvents = this.http.get(this.apiURL);
    //console.log('From getEventsThisWeek: ',this.eventsThisWeekURL);
    return this.thisWeekEvents;
  }

  getEventsNextWeek(){
    this.apiURL = `${this.siteURL}${this.eventsNextWeekURL}`;
    this.nextWeekEvents = this.http.get(this.apiURL);
    //console.log('From getEventsNextWeek: ',this.eventsNextWeekURL);
    return this.nextWeekEvents;
  }

  getEventsThisMonth(){
    this.apiURL = `${this.siteURL}${this.eventsThisMonthURL}`;
    this.thisMonthEvents = this.http.get(this.apiURL);
    //console.log('From getEventsThisMonth: ',this.eventsThisMonthURL);
    return this.thisMonthEvents;
  }

  getEventsNextMonth(){
    this.apiURL = `${this.siteURL}${this.eventsNextMonthURL}`;
    this.nextMonthEvents = this.http.get(this.apiURL);
    //console.log('From getEventsNextMonth: ',this.eventsNextMonthURL);
    return this.nextMonthEvents;
  }

  getEventsJustAnnounced(){
    this.apiURL = `${this.siteURL}${this.eventsJustAnnouncedURL}`;
    this.justAnnounceEvents = this.http.get(this.apiURL);
    //console.log('From getEventsJustAnnouncedURL: ',this.eventsJustAnnouncedURL);
    return this.justAnnounceEvents;
  }

  getEventsMostPopular(){
    this.apiURL = `${this.siteURL}${this.eventsMostPopularURL}`;
    this.mostPopularEvents = this.http.get(this.apiURL);
    //console.log('From getEventsMostPopularURL: ',this.eventsMostPopularURL);
    return this.mostPopularEvents;
  }

  getEventsFilterByTimeCat(time,catId){
    let apiURL = `${this.siteURL}${this.eventsFilteredURL}?filterBy=${time}&catid=${catId}`;
    //console.log('getEventsFilterByTimeCat: ',apiURL);
    this.filteredEvents = this.http.get(apiURL);
    return this.filteredEvents;
  }

  getEventsFilterByMonth(month){
    let apiURL = `${this.siteURL}${this.eventsFilteredURL}?month=${month}`;
    //console.log('getEventsFilterByMonth: ',apiURL);
    this.filteredEvents = this.http.get(apiURL);
    return this.filteredEvents;
  }

  getEventsFilterByMonthCat(month,catId){
    let apiURL = `${this.siteURL}${this.eventsFilteredURL}?month=${month}&catid=${catId}`;
    //console.log('getEventsFilterByMonthCatCat: ',apiURL);
    this.filteredEvents = this.http.get(apiURL);
    return this.filteredEvents;
  }

  // convert javascript object to x-www-form-urlencoded format
  JSON_to_URLEncoded(element, key?, list?) {
    var list = list || [];
    if (typeof element == "object") {
      for (var idx in element)
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          list
        );
    } else {
      list.push(key + "=" + encodeURIComponent(element));
    }
    return list.join("&");
  }

  //Tickera EventPro Main App Endpoint Serial #1
  getAnAttendee(attendeeId) {
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let secretKey = this.encryptMe(this.secretkey);
    let dataObj = {
      attendee_id: attendeeId,
      user_id: this.userId,
      secret_key: secretKey
    };

    let finalDataObj = this.JSON_to_URLEncoded(dataObj);
    this.apiURL = `${this.siteURL}${this.tickeraAPI}attendee`;
    //console.log('getAnAttendee API URL: ', this.apiURL);
    return new Promise((resolve) => {
      this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
        resolve(successResp);
      })
    });
  }

  //Tickera EventPro Main App Endpoint Serial #2
  updateAttnNow(dataObj) {
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let secretKey = this.encryptMe(this.secretkey);
    dataObj.secret_key = secretKey;
    dataObj.user_id = this.userId;

    let finalDataObj = this.JSON_to_URLEncoded(dataObj);
    this.apiURL = `${this.siteURL}${this.tickeraAPI}edit-attendee`;
    //console.log('updateAttnNow API URL: ', this.apiURL);
    return new Promise((resolve) => {
      this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
        resolve(successResp);
      })
    });
  }


  //Tickera EventPro Main App Endpoint Serial #3
  setEventViewCount(eventId) {
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let secretKey = this.encryptMe(this.secretkey);
    let dataObj = {
      event_id: eventId,
      user_id: this.userId,
      secret_key: secretKey
    };

    let finalDataObj = this.JSON_to_URLEncoded(dataObj);
    this.apiURL = `${this.siteURL}${this.tickeraAPI}event-view-count`;
    //console.log('setEventViewCount API URL: ', this.apiURL);
    return new Promise((resolve) => {
      this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
        resolve(successResp);
      })
    });
  }

  //Tickera EventPro Main App Endpoint Serial #4
  getTickets(userId,platform,page) {
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let secretKey = this.encryptMe(this.secretkey);
    let dataObj = {
      user_id: userId,
      platform_name: platform,
      page_number: page,
      per_page: 100,
      secret_key: secretKey
    };

    //console.log('getTickets dataObj: ', dataObj);
    let finalDataObj = this.JSON_to_URLEncoded(dataObj);
    this.apiURL = `${this.siteURL}${this.tickeraAPI}appevent-get-my-tickets`;
    //console.log('getTickets API URL: ', this.apiURL);
    return new Promise((resolve) => {
      this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
        resolve(successResp);
      })
    });
  }


  //Tickera EventPro Main App Endpoint Serial #5
  getCountries() {
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    this.apiURL = `${this.siteURL}${this.tickeraAPI}countries`;
    //console.log('getCountries API URL: ', this.apiURL);
    return new Promise((resolve) => {
      this.http.post(this.apiURL, '', {headers} ).subscribe((successResp) => {
        resolve(successResp);
      })
    });
  }

  //Tickera EventPro Main App Endpoint Serial #6
  getStatesByCountry(country) {
    let headers = new HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    
    let dataObj = {
      country_code: country,
    };

    let finalDataObj = this.JSON_to_URLEncoded(dataObj);
    this.apiURL = `${this.siteURL}${this.tickeraAPI}states`;
    //console.log('getStatesByCountry API URL: ', this.apiURL);
    return new Promise((resolve) => {
      this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
        resolve(successResp);
      })
    });
  }







  // for capella
    getUserDataCapella() {
      let headers = new HttpHeaders ({
        'Content-Type': 'application/x-www-form-urlencoded'
      });

      let secretKey = this.encryptMe(this.capellaSecretKey);

      let dataObj = {
        email_id: this.salt,
        secret_key: secretKey
      };

      let finalDataObj = this.JSON_to_URLEncoded(dataObj);
      this.apiURL = `${this.capellaSiteURL}${this.tickeraAPI}get-user-by-email`;
      //console.log('getUserData TC API URL: ', this.dataUrl);
      return new Promise((resolve) => {
        this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
          resolve(successResp);
        })
      });
    }

    getTicketsCapella(userId,platform,page) {
      let headers = new HttpHeaders ({
        'Content-Type': 'application/x-www-form-urlencoded'
      });
      let secretKey = this.encryptMe(this.capellaSecretKey);
      let dataObj = {
        user_id: userId,
        platform_name: platform,
        page_number: page,
        per_page: 20,
        secret_key: secretKey
      };
  
      //console.log('getTickets dataObj: ', dataObj);
      let finalDataObj = this.JSON_to_URLEncoded(dataObj);
      this.apiURL = `${this.capellaSiteURL}${this.tickeraAPI}appevent-get-my-tickets`;
      //console.log('getTickets API URL: ', this.apiURL);
      return new Promise((resolve) => {
        this.http.post(this.apiURL, finalDataObj, {headers} ).subscribe((successResp) => {
          resolve(successResp);
        })
      });
    }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  
  { path: 'home',
  children: [
    {
     path : '',
     loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },

    {
     path : ':eventId',
     loadChildren: () => import('./pages/eventdetails/eventdetails.module').then( m => m.EventdetailsPageModule),
     canActivate: [AuthGuard]
    }
    ]
  },

  { path: 'calendar',
      children: [
      {
        path : '',
        loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule)
      },

      {
        path : ':eventId',
        loadChildren: () => import('./pages/eventdetails/eventdetails.module').then( m => m.EventdetailsPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },

  { path: 'pushedevent',
    children: [
      {
      path : '',
      loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
      },

      {
      path : ':eventId',
      loadChildren: () => import('./pages/eventdetails/eventdetails.module').then( m => m.EventdetailsPageModule),
      canActivate: [AuthGuard]
      }
      ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'webcheckout',
    loadChildren: () => import('./pages/webcheckout/webcheckout.module').then( m => m.WebcheckoutPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
